import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Seo from '@src/components/seo/SEO';
import { renderSwitch } from '@src/utils/Render';

// utils

const ShopPage = () => {
    const { page } = useStaticQuery(graphql`
        {
            page: craftShopShopAllEntry {
                id: remoteId
                title
                typeHandle
                dateUpdated
                seo {
                    title
                    description
                    keywords {
                        keyword
                    }
                    advanced {
                        robots
                        canonical
                    }
                    social {
                        twitter {
                            image {
                                ... on Craft_images_Asset {
                                    url
                                }
                            }
                        }
                        facebook {
                            image {
                                ... on Craft_images_Asset {
                                    url
                                }
                            }
                        }
                    }
                }
                contentMatrix {
                    ...RichTextFragment
                    ...ProductsBlockFragment
                    ...TextGridBlockFragment
                    ...CalloutCtaBlockFragment
                    ...LargeCalloutBlockFragment
                    ...RainyDaysCalloutFragment
                    ...SingleMediaFragment
                    ...TextAndImageGridBlockFragment
                    ...ThumbnailImageGridFragment
                    ...TwoUpImageBlockFragment
                    ...ImageCarouselFragment
                    ...TextAndImageCtaBlockFragment
                    ...NewsletterSignupFragment
                    ...TextImageBlockFragment
                    ...PressBlockFragment
                    ...BannerBlockFragment
                    ...TimelineBlockFragment
                    ...FigureLinkGridFragment
                    ...ContactFormFragment
                    ...CloudGraphic
                    ...RainGraphic
                    ...StoremapperEmbed
                }
            }
        }
    `);

    const { seo } = page;
    const keywords = [];

    if (seo.keywords) {
        seo.keywords.forEach(keyword => {
            keywords.push(keyword.keyword);
        });
    }

    const content = page.contentMatrix;

    return (
        <>
            <Seo
                title={seo.title}
                canonical={seo.advanced.canonical}
                description={seo.description}
                facebookImage={seo.social.facebook.image?.url}
                keywords={keywords}
                robots={seo.advanced.robots}
                twitterImage={seo.social.twitter.image?.url}
                modifiedTime={page.dateUpdated}
            />
            <div>{content?.map((block, i) => renderSwitch(block, i))}</div>
        </>
    );
};

export default ShopPage;
