import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

function Seo({
    description,
    lang,
    meta,
    title,
    twitterImage,
    facebookImage,
    keywords,
    canonical,
    landing,
    modifiedTime,
    url,
}) {
    const { site } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    description
                    author
                }
            }
        }
    `);

    const metaTitle = title || site.siteMetadata.title;
    const metaDescription = description || site.siteMetadata.description;

    return (
        <Helmet
            title={metaTitle}
            htmlAttributes={{ lang }}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    name: 'keywords',
                    content: keywords,
                },
                {
                    property: `og:locale`,
                    content: `en_US`,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:image`,
                    content: facebookImage,
                },
                {
                    property: `og:type`,
                    content: landing ? `website` : `article`,
                },
                {
                    property: `article:publisher`,
                    content: `https://www.facebook.com/drinkhw/`,
                },
                {
                    property: `article:modified_time`,
                    content: modifiedTime,
                },
                {
                    property: `og:url`,
                    content: url,
                },
                {
                    property: `og:site_name`,
                    content: `Heart Water®`,
                },
                {
                    name: `twitter:card`,
                    content: `summary_large_image`,
                },
                {
                    name: `twitter:creator`,
                    content: site.siteMetadata.author,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `twitter:description`,
                    content: metaDescription,
                },
                {
                    name: `twitter:creator`,
                    content: `@drinkheartwater`,
                },
                {
                    name: `twitter:site`,
                    content: `@drinkheartwater`,
                },
                {
                    name: `twitter:label1`,
                    content: `Written by`,
                },
                {
                    name: `twitter:data1`,
                    content: `@drinkheartwater`,
                },
                {
                    name: `twitter:image`,
                    content: twitterImage,
                },
                {
                    rel: 'canonical',
                    href: canonical,
                },
            ].concat(meta)}
            link={[{ rel: 'icon', type: 'image/svg', href: '/icon.svg' }]}>
            <script type="application/ld+json">
                {`
                        {
                            "@context": "https://schema.org",
                            "@type": "Organization",
                            "url": "https://drinkheartwater.com",
                            "name": "HEART WATER",
                            "description": "Heart Water is an artisan alkaline rainwater that is sustainable, delicious and full of heart. Enjoy bottled rainwater delivered to your door from Austin.",
                            "logo": "https://heartwater-assets.sfo3.digitaloceanspaces.com/images/LogoWhiteBg.svg",
                            "image": "https://heartwater-assets.sfo3.digitaloceanspaces.com/images/HW_Social_08.png",
                            "telephone": "512-253-8098",
                            "email": "info@drinkheartwater.com",
                            "areaServed": "USA",
                            "sameAs": [
                                "https://www.facebook.com/drinkhw/",
                                "https://www.instagram.com/heartwater/",
                                "https://twitter.com/drinkheartwater"
                            ],
                            "founder": {
                                "@type": "Person",
                                "name": "YLIANNA and MICHAEL DADASHI"
                            },
                            "foundingLocation": {
                                "@type": "Place",
                                "name": "USA"
                            }
                        }
                    `}
            </script>
        </Helmet>
    );
}

Seo.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
};

export default Seo;
